export const CONVERSION_PIXEL_SCRIPT = (
  ORDER_ID: string,
  TOTAL_AMOUNT: string
) =>
  `(function(){var x=null,p,q,m,o="36613",l="${ORDER_ID}",i="${TOTAL_AMOUNT}",c="",k="",g="",j="",u="",shadditional="";try{p=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,512)):""}catch(n){p=document.referrer!==null?document.referrer.toString().substring(0,512):""}try{q=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(b){q=document.location}try{m=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,512)):""}catch(z){try{m=q!==null?encodeURIComponent(q.toString().substring(0,512)):""}catch(h){m=""}}var A,y=document.createElement("script"),w=null,v=document.getElementsByTagName("script"),t=Number(v.length)-1,r=document.getElementsByTagName("script")[t];if(typeof A==="undefined"){A=Math.floor(Math.random()*100000000000000000)}w="dx.mountain.com/spx?conv=1&shaid="+o+"&tdr="+p+"&plh="+m+"&cb="+A+"&shoid="+l+"&shoamt="+i+"&shocur="+c+"&shopid="+k+"&shoq="+g+"&shoup="+j+"&shpil="+u+shadditional;y.type="text/javascript";y.src=("https:"===document.location.protocol?"https://":"http://")+w;r.parentNode.insertBefore(y,r)}());`;

export const SUBSCRIPTION_PLANS = [
  {
    id: 'starter',
    name: 'Nevly Starter',
    price: 'FREE',
    features: [
      'Equifax credit score updated monthly',
      'Equifax credit report updated monthly',
      'Real-time credit monitoring alerts',
      'Ability to upgrade subscription from your dashboard',
    ],
  },
  {
    id: 'lite',
    name: 'Nevly Lite',
    price: '$49.99',
    time: '/PER MONTH',
    timeColor: 'white', // Use a valid color name or hex code here
    tagline:
      'Ready to improve your credit score and get help managing your cash flow issues with an interest-free option, this is the plan for you',
    features: [
      'Three bureau credit reports and scores updated monthly',
      'Report audit services',
      'Real-time monitoring alerts',
      'Rent payment reporting',
      'Credit builder card access',
      'Referral program',
      'Rent Advance service with credit limits up to $1,500',
    ],
  },
  {
    id: 'core',
    name: 'Nevly Core',
    price: '$59.99',
    time: '/PER MONTH',
    tagline:
      'Need a bit more fire power than our Lite Plan, here you go. Everything in our Lite Plan plus:',
    features: [
      'Two years of past rent reporting',
      'Rent Advance service with credit limits up to $2,000',
    ],
  },
];
