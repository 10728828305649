import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import Intercom from '@intercom/messenger-js-sdk';
import { environment } from '../environments/environment';

Intercom({
  app_id: environment.intercom.id,
  custom_launcher_selector: '#intercom-launcher',
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'nevl';
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
}
