import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginService } from '../services/login/login.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.css'],
})
export class VerificationCodeComponent implements OnInit {
  faArrowRight = faArrowRight;

  @Input() phoneNumber: string | null = null;
  @Input() email: string | null = null;
  code: string = '';

  @Output() close = new EventEmitter<void>();

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  verifyCode(): void {
    const payload = this.phoneNumber
      ? { phoneNumber: this.phoneNumber, code: this.code }
      : { email: this.email, code: this.code };

    const verifyMethod = this.phoneNumber
      ? this.loginService.verifyPhoneOtp(payload)
      : this.loginService.verifyEmailOtp(payload);

    if (!verifyMethod) {
      this.toastr.error('Invalid input provided.', 'Error');
      return;
    }

    verifyMethod.subscribe({
      next: (response) =>
        this.handleResponse(response, 'Verification successful'),
      error: ({ error }) => this.handleError(error, 'Verification failed'),
    });
  }

  resendCode(): void {
    const payload = {
      ...(this.email ? { email: this.email } : {}),
      ...(this.phoneNumber ? { phone: this.phoneNumber } : {}),
    };

    if (!Object.keys(payload).length) {
      this.toastr.error(
        'Email or phone number is required to resend the code.',
        'Error'
      );
      return;
    }

    this.loginService.otpLogin(payload).subscribe({
      next: () => this.toastr.success('Code re-sent successfully', 'Success'),
      error: (error) => this.handleError(error, 'Failed to resend code'),
    });
  }

  closeModal(): void {
    this.close.emit();
  }

  private handleResponse(response: any, successMessage: string): void {
    if (response.error) {
      this.toastr.error(
        response?.error ?? 'An unexpected error occurred.',
        'Error',
        {
          timeOut: 5000,
          progressBar: true,
          progressAnimation: 'increasing',
          closeButton: true,
          positionClass: 'toast-top-right',
        }
      );
    } else {
      this.toastr.success(response?.message ?? successMessage, 'Success', {
        timeOut: 5000,
        progressBar: true,
        progressAnimation: 'increasing',
        closeButton: true,
        positionClass: 'toast-top-right',
      });

      window.location.href = `${environment.webApp.url}/#/login?accessToken=${response?.data?.access}`;
    }
    this.close.emit();
  }

  private handleError(error: any, errorMessage: string): void {
    this.toastr.error(
      error?.data?.message ?? 'An unexpected error occurred.',
      'Error',
      {
        timeOut: 5000,
        progressBar: true,
        progressAnimation: 'increasing',
        closeButton: true,
        positionClass: 'toast-top-right',
      }
    );
  }
}
