// palns-subscription.component.ts
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ViewChild, ViewContainerRef } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CONVERSION_PIXEL_SCRIPT, SUBSCRIPTION_PLANS } from '../app.constants';
import { v4 as uuidv4 } from 'uuid';

interface Plan {
  id: string;
  name: string;
  price: string;
  features: string[];
  time?: string;
  tagline?: string;
  timeColor?: string;
}

@Component({
  selector: 'app-palns-subscription',
  templateUrl: './palns-subscription.component.html',
  styleUrls: ['./palns-subscription.component.css'],
})
export class PalnsSubscriptionComponent implements OnInit {
  faCheck = faCheck;
  @ViewChild('popupContainer', { read: ViewContainerRef })
  popupContainer!: ViewContainerRef;
  private conversionScript?: HTMLScriptElement;
  private scriptLoaded: boolean = false;

  constructor(private renderer: Renderer2) {}

  openPopup(plan: Plan) {
    this.popupContainer.clear();
    const popupRef = this.popupContainer.createComponent(PopupComponent);
    popupRef.instance.closePopup.subscribe(() => {
      popupRef.destroy();
    });

    this.loadConversionScript(plan);
  }

  plans = SUBSCRIPTION_PLANS;

  private loadConversionScript(plan: Plan): void {
    if (this.scriptLoaded) return;

    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.text = CONVERSION_PIXEL_SCRIPT(
      uuidv4(),
      plan.price.includes('$')
        ? plan.price.substring(1, plan.price.length)
        : plan.price === 'FREE'
        ? '0.00'
        : plan.price
    );

    this.renderer.appendChild(document.body, script);
    this.conversionScript = script;
    this.scriptLoaded = true;
  }

  ngOnInit() {
    console.log(this.plans);
  }

  ngOnDestroy(): void {
    if (this.conversionScript) {
      this.renderer.removeChild(document.body, this.conversionScript);
      this.conversionScript = undefined;
      this.scriptLoaded = false;
    }
  }
}
